'use client';

import { css } from '@bts-web/utils-style-engine';
import {
  MaximumFractionDigits,
  assetUnitsValueToPercentage,
  getRoundedPrecision,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import { PortfolioPeriod } from '@bts-web/data-layer/server';
import { usePortfolioContext } from '@bts-web/utils-context';
import { PriceVariation, type Locale } from '../../../common';
import { WrapperForMobileStickySectionHeader } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/WrapperForMobileStickySectionHeader/WrapperForMobileStickySectionHeader';

const containerStyles = css({
  backgroundColor: 'screen_background.primary',
  display: 'flex',
  gap: 'extra_small_2',
  py: 'small',
  px: 'medium',
  justifyContent: 'space-between',
  '@media (min-width: 1024px)': {
    gap: 'medium',
    justifyContent: 'flex-start',
  },
});

const titleStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  '@media (min-width: 1024px)': {
    fontSize: 'title.large',
    fontWeight: 'title.large',
    letterSpacing: 'title.large',
    lineHeight: 'title.large',
  },
});

const valueStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  '@media (min-width: 1024px)': {
    fontSize: 'display.large_medium',
    fontWeight: 'display.large_medium',
    letterSpacing: 'display.large_medium',
    lineHeight: 'display.large_medium',
  },
});

const overallStyles = css({
  display: 'flex',
  gap: {
    base: 'extra_small_2',
    lg: 'medium',
  },
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export type PortfolioSummaryTranslations = {
  portfolioTitle: string;
  inOneYear: string;
  inOneMonth: string;
  inOneWeek: string;
  in24Hours: string;
  inSixMonths: string;
  inMaxYears: string;
};

export interface PortfolioSummaryProps {
  translations: PortfolioSummaryTranslations;
  locale: Locale;
}

const PortfolioSummary = ({ translations, locale }: PortfolioSummaryProps) => {
  const { overallAmount, returnPercentage, timeSpan } = usePortfolioContext();

  const overallAmountValue = overallAmount || {
    value: '0',
    precision: MaximumFractionDigits.FIAT,
  };

  const priceVariationVisual =
    !returnPercentage || returnPercentage === 0
      ? 'filledNeutral'
      : returnPercentage > 0
        ? 'filledPositive'
        : 'filledNegative';

  const {
    portfolioTitle,
    in24Hours,
    inOneWeek,
    inOneMonth,
    inOneYear,
    inSixMonths,
    inMaxYears,
  } = translations;

  const timestampOptions: Record<PortfolioPeriod, string> = {
    DAY: in24Hours,
    WEEK: inOneWeek,
    MONTH: inOneMonth,
    YEAR: inOneYear,
    SIX_MONTHS: inSixMonths,
    MAX: inMaxYears,
  };

  const percentageMissing =
    returnPercentage === 0 ||
    returnPercentage === null ||
    returnPercentage === undefined;

  const trend = percentageMissing
    ? 'neutral'
    : returnPercentage > 0
      ? 'positive'
      : 'negative';

  const timeStampText = timestampOptions[timeSpan];

  return (
    <WrapperForMobileStickySectionHeader>
      <div className={containerStyles} aria-label="portfolio floating header">
        <div className={overallStyles}>
          <h2 className={titleStyles}>{portfolioTitle}</h2>

          <span
            className={valueStyles}
            data-testid="overall-amount"
            suppressHydrationWarning
          >
            {intlAssetValueFormatting(
              Number(getRoundedPrecision(overallAmountValue)),
              {
                locale,
                fractionDigits: overallAmountValue?.precision,
                currency: 'EUR',
              },
            )}
          </span>
        </div>

        <div
          className={css({
            display: 'flex',
          })}
        >
          <PriceVariation
            size="mediumAccent"
            trend={trend}
            visual={priceVariationVisual}
            percentage={`${assetUnitsValueToPercentage(returnPercentage || 0, {
              locale,
              isAbsolute: true,
            })}`}
            // ! value="+3.267,30 €"
            // TODO: fetch above value (equivalent in FIAT of return percentage) from query once API supports it
            timestampText={timeStampText}
            timestampPosition="top"
          />
        </div>
      </div>
    </WrapperForMobileStickySectionHeader>
  );
};

export { PortfolioSummary };
