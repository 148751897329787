'use client';

import { css, stack, flex } from '@bts-web/utils-style-engine';
import { usePortfolioContext } from '@bts-web/utils-context';
import {
  assetUnitsValueToPercentage,
  intlAssetValueFormatting,
} from '@bts-web/utils-formatting';
import {
  type Locale,
  itemslistHeaderStyles,
  PriceVariation,
} from '../../../common';
import { useTradeSellDisabler } from '../../../trade/TradeDrawer/useTradeSellDisabler/useTradeSellDisabler';

const titleStyles = css({
  fontSize: 'headline.small_medium',
  fontWeight: 'headline.small_medium',
  letterSpacing: 'headline.small_medium',
  lineHeight: 'headline.small_medium',
  px: 'medium',
});

const mediumText = {
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
};

const labelStyles = css({
  color: 'neutrals.text_secondary',
  ...mediumText,
});

const valueStyles = css({
  color: 'neutrals.text_primary',
  ...mediumText,
});

export interface OverviewListElementProps {
  label: string;
  value: number;
  percentage: number;
  locale: Locale;
}

const OverviewListElement = ({
  label,
  value,
  percentage,
  locale,
}: OverviewListElementProps) => {
  let trend: 'neutral' | 'positive' | 'negative' = 'neutral';

  let percentageVisual: 'filledNeutral' | 'filledPositive' | 'filledNegative' =
    'filledNeutral';

  if (value > 0) {
    trend = 'positive';

    percentageVisual = 'filledPositive';
  }

  if (value < 0) {
    trend = 'negative';

    percentageVisual = 'filledNegative';
  }

  return (
    <li
      className={flex({ gap: 'extra_small_2' })}
      aria-label="portfolio-overview-section"
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          flexGrow: '1',
        })}
      >
        <span className={labelStyles}>{label}</span>
        <span className={valueStyles} suppressHydrationWarning>
          {intlAssetValueFormatting(value, {
            locale,
            currency: 'EUR',
          })}
        </span>
      </div>

      <PriceVariation
        trend={trend}
        visual={percentageVisual}
        percentage={assetUnitsValueToPercentage(percentage, {
          locale,
          isAbsolute: true,
        })}
        size="small"
      />
    </li>
  );
};

export interface PortfolioOverviewProps extends React.PropsWithChildren {
  locale: Locale;
  translations: {
    portfolioOverview: string;
    gains: string;
    allTimeReturn: string;
    assetName: string;
    youOwn: string;
  };
}

const PORTFOLIO_DISABLE_SELL_KEY = 'PORTFOLIO_DISABLE_SELL_KEY';

// TODO update stats once API is ready
const stats = {
  gains: 0.0,
  allTimeReturn: 0.0,
  percentage: 0.0,
};

const PortfolioOverview = ({
  translations,
  locale,
  children,
}: PortfolioOverviewProps) => {
  const { assetGroups } = usePortfolioContext();

  const isNotOwningAssets =
    !assetGroups || (assetGroups && assetGroups?.length === 0);

  useTradeSellDisabler({
    disablerKey: PORTFOLIO_DISABLE_SELL_KEY,
    shouldDisable: isNotOwningAssets === true,
  });

  return (
    <>
      <div className={stack({ gap: 'small' })}>
        <h2 className={titleStyles} data-testid="portfolio-overview">
          {translations.portfolioOverview}
        </h2>

        <div className={itemslistHeaderStyles}>
          <span>{translations.assetName}</span>
          <span>{translations.youOwn}</span>
        </div>

        {stats && !assetGroups?.length && (
          <ul
            className={stack({
              gap: 'small',
              paddingLeft: 'medium',
              paddingRight: 'medium',
              paddingBottom: 'small',
            })}
          >
            <OverviewListElement
              locale={locale}
              label={translations.gains}
              value={stats.gains}
              percentage={stats.percentage}
            />

            <OverviewListElement
              locale={locale}
              label={translations.allTimeReturn}
              value={stats.allTimeReturn}
              percentage={stats.percentage}
            />
          </ul>
        )}
      </div>

      {children}
    </>
  );
};

export { PortfolioOverview };
