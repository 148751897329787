import { TOptions } from 'i18next';

const initialPortfolioTranslations = {
  latestTransactions: 'dashboard_portfolio_latest_transactions',
  viewAllTransactions: 'view_all_transactions',
  latestTransactionsDescription: 'portfolio_latest_transaction_description',
  emptyStateNoTransactions: 'empty_state_no_transactions',
  grams: 'grams',
  portfolioTitle: 'dashboard_portfolio_title',
  inOneYear: 'in_one_year',
  inOneMonth: 'in_one_month',
  inOneWeek: 'in_one_week',
  in24Hours: 'in_24_hours',
  inSixMonths: 'in_six_months',
  inMaxYears: 'in_max_years',
  last24h: 'last_24h',
  lastWeek: 'last_week',
  lastMonth: 'last_month',
  lastYear: 'last_year',
  lastSixMonths: 'last_six_months',
  lastMaxYears: 'last_max_years',
  portfolioOverview: 'dashboard_portfolio_overview',
  gains: 'dashboard_gains',
  allTimeReturn: 'all_time_return',
  crypto: 'dashboard_portfolio_crypto',
  stocks: 'dashboard_portfolio_stocks',
  metals: 'dashboard_portfolio_metals',
  etfs: 'currency_filter_ETF',
  etcs: 'currency_filter_commodities',
  youOwn: 'you_own',
  assetName: 'asset_name',
  transactionDescription: 'transaction_description',
  amount: 'amount',
  goToPrices: 'go_to_prices_button',
  emptyPortfolioSubtitle: 'portfoliio_no_asset_prompt_subtitle',
  emptyPortfolioTitle: 'portfolio_no_asset_prompt_title',
};

export type EmptyPortfolioTranslations = {
  emptyPortfolioTitle: string;
  emptyPortfolioSubtitle: string;
  goToPrices: string;
};

export type PortfolioContentTranslationsKeys = {
  portfolioTitle: string;
  inOneYear: string;
  inOneMonth: string;
  inOneWeek: string;
  in24Hours: string;
  inSixMonths: string;
  inMaxYears: string;
  last24h: string;
  lastWeek: string;
  lastMonth: string;
  lastYear: string;
  lastSixMonths: string;
  lastMaxYears: string;
  portfolioOverview: string;
  gains: string;
  allTimeReturn: string;
  crypto: string;
  stocks: string;
  metals: string;
  etfs: string;
  etcs: string;
  youOwn: string;
  assetName: string;
} & EmptyPortfolioTranslations;

export type PortfolioLatestTransactionsTranslationsKeys = {
  latestTransactions: string;
  viewAllTransactions: string;
  latestTransactionsDescription: string;
  emptyStateNoTransactions: string;
  amount: string;
  transactionDescription: string;
};

export type PortfolioTranslationsKeys = PortfolioContentTranslationsKeys &
  PortfolioLatestTransactionsTranslationsKeys &
  EmptyPortfolioTranslations;

const getPortfolioTranslations = (
  t: (translationKey: keyof PortfolioTranslationsKeys) => string,
): PortfolioTranslationsKeys => {
  return Object.entries(initialPortfolioTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof PortfolioTranslationsKeys] = t(
        value as keyof PortfolioTranslationsKeys,
      );

      return acc;
    },
    {} as PortfolioTranslationsKeys,
  );
};

const getPortfolioTranslationsKeyValue = ({
  t,
  translationKey,
  option,
}: {
  t: (translationKey: PortfolioTranslationsKeys, option: TOptions) => string;
  translationKey: PortfolioTranslationsKeys;
  option: TOptions;
}) => t(translationKey, option);

export { getPortfolioTranslations, getPortfolioTranslationsKeyValue };
