import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import type { EmptyPortfolioTranslations } from '../../utils/getPortfolioTranslations';
import { HyperLinkButtonBase } from '../../../common/components/HyperLinkButtonBase/HyperLinkButtonBase';

const containerStyles = css({
  backgroundColor: 'neutrals.fill_quinary',
  borderRadius: 8,
  boxShadow:
    '0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 4px 15px 0px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 'small',
  padding: 'large',
  mx: 'medium',
  marginBottom: 'large',
});

const textContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small',

  '& > a:last-child': {
    marginTop: 'extra_small',
    width: 'fit-content',
  },
});

const titleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.medium_medium',
  fontWeight: 'headline.medium_medium',
  lineHeight: 'headline.medium_medium',
  letterSpacing: 'headline.medium_medium',
});

const subtitleStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  letterSpacing: 'body.medium',
});

interface EmptyPortfolioProps {
  translations: EmptyPortfolioTranslations;
}

const EmptyPortfolio = ({ translations }: EmptyPortfolioProps) => (
  <div className={containerStyles}>
    <div className={textContainerStyles}>
      <h2 className={titleStyles}>{translations.emptyPortfolioTitle}</h2>
      <p className={subtitleStyles}>{translations.emptyPortfolioSubtitle}</p>
      <HyperLinkButtonBase visual="primary" size="small" to="/assets/discover">
        {translations.goToPrices}
      </HyperLinkButtonBase>
    </div>
    <Icon icon="arrow-growth" theme="regular" width={32} height={32} />
  </div>
);

export { EmptyPortfolio };
