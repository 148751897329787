import {
  RangeSelectorConfigItem,
  TimeAmounts,
} from '@bts-web/design-system/component/chart';
import { PortfolioContentTranslationsKeys } from './getPortfolioTranslations';

export const getChartRangeSelectorConfig = (
  translations: PortfolioContentTranslationsKeys,
): RangeSelectorConfigItem[] => [
  {
    timeAmountId: TimeAmounts.OneDay,
    text: translations.last24h,
  },
  {
    timeAmountId: TimeAmounts.SevenDays,
    text: translations.lastWeek,
  },
  {
    timeAmountId: TimeAmounts.OneMonth,
    text: translations.lastMonth,
  },
  {
    timeAmountId: TimeAmounts.SixMonths,
    text: translations.lastSixMonths,
  },
  {
    timeAmountId: TimeAmounts.OneYear,
    text: translations.lastYear,
  },
  {
    timeAmountId: TimeAmounts.MaxYears,
    text: translations.lastMaxYears,
  },
];
