'use client';

import { useMemo, type ReactElement } from 'react';
import { PortfolioPeriod } from '@bts-web/data-layer/server';
import { usePortfolioContext } from '@bts-web/utils-context';
import {
  CHART_DEFAULT_DATASET,
  DataSeriesEntry,
  TimeAmounts,
} from '@bts-web/design-system/component/chart';
import { type AreaChartComponentProps, type Locale } from '../../../common';
import { getRoundedPrecision } from '@bts-web/utils-formatting';

const timespans: Record<TimeAmounts, PortfolioPeriod> = {
  [TimeAmounts.OneYear]: 'YEAR',
  [TimeAmounts.OneMonth]: 'MONTH',
  [TimeAmounts.SevenDays]: 'WEEK',
  [TimeAmounts.OneDay]: 'DAY',
  [TimeAmounts.SixMonths]: 'SIX_MONTHS',
  [TimeAmounts.MaxYears]: 'MAX',
};

const PortfolioAreaChart = ({
  rangeSelectorsConfig,
  locale,
  loadingContentSlot,
  AreaChartComponent,
}: {
  rangeSelectorsConfig: AreaChartComponentProps['rangeSelectorsConfig'];
  locale: Locale;
  loadingContentSlot: React.ReactElement;
  AreaChartComponent: ({
    locale,
    isDisabled,
    isLoading,
    loadingContentSlot,
    seriesData,
    rangeSelectCallback,
    rangeSelectorsConfig,
    showMinMax,
  }: AreaChartComponentProps) => ReactElement;
}) => {
  const { assetGroups, historicalData, isLoading, changePeriod } =
    usePortfolioContext();

  const chartDataSet = useMemo(() => {
    if (historicalData) {
      return historicalData.map((entry, index) => {
        if (!entry) return CHART_DEFAULT_DATASET;

        const date = new Date(entry.period!);

        return [date.getTime(), Number(getRoundedPrecision(entry.value))];
      }) as Array<DataSeriesEntry>;
    }

    return CHART_DEFAULT_DATASET;
  }, [historicalData]);

  const rangeSelectedCallback = (selectedTimeRange: TimeAmounts) => {
    changePeriod(
      timespans[selectedTimeRange as TimeAmounts] as PortfolioPeriod,
    );
  };

  return (
    <AreaChartComponent
      locale={locale}
      isDisabled={!assetGroups?.length}
      isLoading={isLoading}
      loadingContentSlot={loadingContentSlot}
      visual={'neutral'}
      seriesData={chartDataSet}
      rangeSelectorsConfig={rangeSelectorsConfig}
      rangeSelectCallback={rangeSelectedCallback}
      showMinMax
      showTimeframe
    />
  );
};

export { PortfolioAreaChart };
